import React from 'react';
import { Link, graphql } from 'gatsby';
import Image from 'gatsby-image';

import { Bio, Layout, SEO, ContentSection, Headline, FactBox } from '@egonoid/gatsby-theme-common';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import styles from './post.module.scss';

const shortcodes = {
  h1: Headline,
  ContentSection,
  FactBox,
};

interface IProps {
  data: any;
  location: any;
  pageContext: any;
}

const BlogPostTemplate: React.FC<IProps> = ({ data, pageContext, location }) => {
  const post = data.mdxBlogPost;
  const siteTitle = data.site.siteMetadata.title;
  const { previous, next } = pageContext;

  return (
    <Layout location={location} title={siteTitle} showLogo={true}>
      <SEO title={post.title} description={post.excerpt} />
      <div className={styles.post__container}>
        <ContentSection>
          <article>
            <header>
              <div className={styles['post__header--category']}>
                Published in{' '}
                <Link to={`/categories/${post.category.toLowerCase()}`}>{post.category}</Link>, on{' '}
                {post.date}
              </div>
              <h1 className={styles['post__header--title']}>{post.title}</h1>
            </header>
            <div className={styles['post__header--teaser']}>{post.excerpt}</div>
            <div className="cell medium-offset-1 medium-10 small-12">
              <div className={styles['post__image']}>
                {post?.image?.childImageSharp && (
                  <Image
                    fluid={post.image.childImageSharp.fluid}
                    alt={post.imageAlt ? post.imageAlt : post.excerpt}
                  />
                )}
                <div className={styles['post__image_credit']}>
                  Credit:{' '}
                  <a href={post.imageAuthorLink} target="_blank" rel="noopener noreferrer">
                    {post.imageAuthor}
                  </a>
                </div>
              </div>
            </div>

            <div className={styles.post__main}>
              <MDXProvider components={shortcodes}>
                <MDXRenderer>{post.body}</MDXRenderer>
              </MDXProvider>
            </div>

            <footer className={styles.post__footer}>
              <hr
                style={{
                  marginBottom: '1rem',
                }}
              />
              <div className={styles['post__tags']}>
                <ul>
                  {post.tags.map((value, index) => {
                    return (
                      <li key={index} className="label">
                        <Link to={`/tags/${value.toLowerCase()}`}>{value}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {post?.author && (
                <Bio
                  author={post.author.fullname}
                  bio={post.author.bio}
                  avatar={post.author.avatar}
                />
              )}
            </footer>
          </article>

          <nav>
            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li>
                {previous && (
                  <Link to={previous.fields.slug} rel="prev">
                    ← {previous.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={next.fields.slug} rel="next">
                    {next.title} →
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </ContentSection>
      </div>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdxBlogPost(slug: { eq: $slug }) {
      id
      title
      date(formatString: "MMMM DD, YYYY")
      category
      tags
      image {
        childImageSharp {
          fluid(maxWidth: 1280, maxHeight: 853) {
            ...GatsbyImageSharpFluid
            src
          }
        }
      }
      imageAuthor
      imageAuthorLink
      author {
        fullname
        email
        bio
        avatar {
          childImageSharp {
            fixed(width: 80, height: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      excerpt
      body
    }
  }
`;
